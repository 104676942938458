import React from 'react';
import './team.css';
import team1 from '../../../assets/team-1.jpg';
import team2 from '../../../assets/team-2.jpg';
import team3 from '../../../assets/team-3.jpg';

export function Team() {
    return (
        <section id="team" className="team">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <h2>Our Executive Team</h2>
                    <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fuga eum quidem</p>
                </div>

                <div className="row">

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div className="member" data-aos="zoom-in">
                            <div className="member-img">
                                <img src={team1} className="img-fluid" alt=""/>
                                <div className="social">
                                    <a href=""><i className="icofont-twitter"/></a>
                                    <a href=""><i className="icofont-facebook"/></a>
                                    <a href=""><i className="icofont-instagram"/></a>
                                    <a href=""><i className="icofont-linkedin"/></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Duke Mpapele</h4>
                                <span>Chairperson</span>
                                <p>Animi est delectus alias quam repellendus nihil nobis dolor. Est sapiente
                                    occaecati et dolore. Omnis aut ut nesciunt explicabo qui. Eius nam deleniti ut
                                    omnis</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div className="member" data-aos="zoom-in" data-aos-delay="100">
                            <div className="member-img">
                                <img src={team2} className="img-fluid" alt=""/>
                                <div className="social">
                                    <a href=""><i className="icofont-twitter"/></a>
                                    <a href=""><i className="icofont-facebook"/></a>
                                    <a href=""><i className="icofont-instagram"/></a>
                                    <a href=""><i className="icofont-linkedin"/></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Charlotte Luzuka</h4>
                                <span>Executive Assistant</span>
                                <p>Aspernatur iste esse aliquam enim et corporis. Molestiae voluptatem aut eligendi
                                    quis aut. Libero vel amet voluptatem eos rerum non doloremque</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div className="member" data-aos="zoom-in" data-aos-delay="200">
                            <div className="member-img">
                                <img src={team3} className="img-fluid" alt=""/>
                                <div className="social">
                                    <a href=""><i className="icofont-twitter"/></a>
                                    <a href=""><i className="icofont-facebook"/></a>
                                    <a href=""><i className="icofont-instagram"/></a>
                                    <a href=""><i className="icofont-linkedin"/></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>TBD</h4>
                                <span>Group Financial Controller</span>
                                <p>Ut enim possimus nihil cupiditate beatae. Veniam facere quae non qui
                                    necessitatibus rerum eos vero. Maxime sit sunt quo dolor autem est qui
                                    quaerat</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}
