import React from "react";
import {Header} from "../../Header/Header";
import {Hero} from "./Hero/Hero";
import {Portfolio} from "./Portfolio/Portfolio";

export function InvestmentPortfolio() {
    return (
        <>
            <div id={'investment-portfolio'}>
                <Header/>
                <Hero/>
                <Portfolio/>
                <a href="#hero-invest" className="back-to-top"><i className="icofont-simple-up"/></a>
            </div>
        </>
    )
}
