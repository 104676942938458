import AfricanProficientGroupImage from './img/african-proficient-group-image.jpg';
import RatheHoldingsImage from './img/rathe-holdings-image.jpg';
import TheFMCGGroupImage from './img/the-fmcg-group-image.jpg';
import VestrotrimImage from './img/vestrotrim-image.jpg';
import AfricanOpulentHoldingsImage from './img/african-opulent-holdings-image.jpg';
import LeveragePointInnovationsImage from './img/leverage-point-innovations-image.jpg';
import AfricanProficientCapitalImage from './img/african-proficient-capital-image.jpg';
import AfricanFarmingOperationsImage from './img/african-farming-operations-image.jpg';
import MasterProServiceContractorsImage from './img/masterpro-service-contractors-image.jpg';

export const portfolio = [
    {
        id: 1,
        name: 'African Proficient Group',
        description: 'Ut quas omnis est. Non et aut tempora dignissimos similique in dignissimos. Sit incidunt et odit iusto',
        website: 'https://africanpg.co.za',
        image: {url: AfricanProficientGroupImage, alt: 'African Proficient Group Image'}
    },
    {
        id: 2,
        name: 'Rathe Holdings',
        description: 'Modi ut et delectus. Modi nobis saepe voluptates nostrum. Sed quod consequatur quia provident dera',
        website: 'http://ratheholdings.yelah.co.za',
        image: {url: RatheHoldingsImage, alt: 'Rathe Holdings Image'}
    },
    {
        id: 3,
        name: 'Vestrotrim',
        description: 'Ut quas omnis est. Non et aut tempora dignissimos similique in dignissimos. Sit incidunt et odit iusto',
        website: 'http://vestrotrim.yelah.co.za',
        image: {url: VestrotrimImage, alt: 'Vestrotrim Image'}
    },
    {
        id: 4,
        name: 'The FMCG Group',
        description: 'Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore',
        website: 'http://fmcg.yelah.co.za',
        image: {url: TheFMCGGroupImage, alt: 'The FMCG Group Image'}
    },
    {
        id: 5,
        name: ' African Opulent Holdings',
        description: 'Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore',
        website: '#',
        image: {url: AfricanOpulentHoldingsImage, alt: ''}
    },
    {
        id: 6,
        name: 'Leverage Point Innovations',
        description: 'Modi ut et delectus. Modi nobis saepe voluptates nostrum. Sed quod consequatur quia provident dera',
        website: 'http://leveragepointinnovations.yelah.co.za/',
        image: {url: LeveragePointInnovationsImage, alt: ''}
    },
    {
        id: 7,
        name: 'African Proficient Capital',
        description: 'Modi ut et delectus. Modi nobis saepe voluptates nostrum. Sed quod consequatur quia provident dera',
        website: 'http://african-proficient-capital.yelah.co.za/',
        image: {url: AfricanProficientCapitalImage, alt: 'African Proficient Capital Image'}
    },
    {
        id: 8,
        name: 'African Farming Operations',
        description: 'Et architecto provident deleniti facere repellat nobis iste. Id facere quia quae dolores dolorem tempore',
        website: 'http://african-farming-operations.yelah.co.za/',
        image: {url: AfricanFarmingOperationsImage, alt: ''}
    },
    {
        id: 9,
        name: 'MasterPro Service Contractors',
        description: 'Ut quas omnis est. Non et aut tempora dignissimos similique in dignissimos. Sit incidunt et odit iusto',
        website: 'http://masterpro.yelah.co.za/',
        image: {url: MasterProServiceContractorsImage, alt: ''}
    }
]
