import React from 'react';
import './hero.css';

export function Hero() {
    return (
        <section id="hero-invest">
            <div className="hero-container">
                <h1 data-aos="zoom-in">Our Investment Portfolio</h1>
            </div>
        </section>
    );
}
