import React from 'react';
import logo from '../assets/logo.png';
import './footer.css';

export function Footer() {
    return (
        <footer id="footer">

            <div className="footer-top">

                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <a href="#header" className="scrollto footer-logo">
                                <img src={logo} alt="logo"/>
                            </a>
                            <p>"Breathe and live excellence"</p>
                        </div>
                    </div>

                    <div className="social-links">
                        <a href="#" className="twitter"><i className="bx bxl-twitter"/></a>
                        <a href="#" className="facebook"><i className="bx bxl-facebook"/></a>
                        <a href="#" className="instagram"><i className="bx bxl-instagram"/></a>
                        <a href="#" className="google-plus"><i className="bx bxl-skype"/></a>
                        <a href="#" className="linkedin"><i className="bx bxl-linkedin"/></a>
                    </div>

                </div>
            </div>

            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; Copyright <strong><span>Elleben Holdings</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Developed by <a href="https://www.yelah.co.za/">Yelah</a>
                </div>
            </div>
        </footer>
    );
}
