import React from 'react';
import './portfolio.css';
import {portfolio} from '../investment-portfolio.service';

export function Portfolio() {
    return (
        <section className="more-services section-bg">
            <div className="container">

                <div className="row">
                    {portfolio.map(item =>
                        (<div key={item.id} className="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mt-3 mb-lg-0">
                            <div className="card">
                                <img src={item.image.url} className="card-img-top" alt={item.image.alt}/>
                                <div className="card-body">
                                    <h5 className="card-title"><a href={item.website} target={'_blank'}
                                                                  rel={'noreferrer noopener'}>{item.name}</a></h5>
                                    <p className="card-text">{item.description}</p>
                                    <a href={item.website} className="btn" target={'_blank'}
                                       rel={'noreferrer noopener'}>Explore
                                        more</a>
                                </div>
                            </div>
                        </div>)
                    )}
                </div>
            </div>
        </section>
    )
}
