import './App.css';
import {Router} from 'react-router-dom';
import Routes from './routes/routes';
import historyService from './history.service';
import {Footer} from "./Footer/Footer";

import jQuery from 'jquery';
import 'jquery.easing';
import 'jquery-sticky';

function App() {

    (function ($) {
        $(window).on('load', function () {
            if ($('#preloader').length) {
                $('#preloader').delay(100).fadeOut('slow', function () {
                    $(this).remove();
                });
            }

            initializeNavigation();
        });

        const initializeNavigation = () => {
            const scrolltoOffset = $('#header').outerHeight() - 1;
            $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
                var target = $(this.hash);
                if (target.length) {
                    e.preventDefault();

                    var scrollto = target.offset().top - scrolltoOffset;

                    if ($(this).attr("href") == '#header') {
                        scrollto = 0;
                    }

                    $('html, body').animate({
                        scrollTop: scrollto
                    }, 1500, 'easeInOutExpo');

                    if ($(this).parents('.nav-menu, .mobile-nav').length) {
                        $('.nav-menu .active, .mobile-nav .active').removeClass('active');
                        $(this).closest('li').addClass('active');
                    }

                    if ($('body').hasClass('mobile-nav-active')) {
                        $('body').removeClass('mobile-nav-active');
                        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                        $('.mobile-nav-overly').fadeOut();
                    }
                    return false;
                }
            });
            //
            // // Activate smooth scroll on page load with hash links in the url
            $(document).ready(function () {
                if (window.location.hash) {
                    const initial_nav = window.location.hash;
                    if ($(initial_nav).length) {
                        const scrollto = $(initial_nav).offset().top - scrolltoOffset;
                        $('html, body').animate({
                            scrollTop: scrollto
                        }, 1500, 'easeInOutExpo');
                    }
                }
            });

            // Mobile Navigation
            if ($('.nav-menu').length) {
                const $mobile_nav = $('.nav-menu').clone().prop({
                    class: 'mobile-nav d-lg-none'
                });
                $('body').append($mobile_nav);
                $('.mobile-nav .nav-logo').remove();
                $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
                $('body').append('<div class="mobile-nav-overly"></div>');

                $(document).on('click', '.mobile-nav-toggle', function (e) {
                    $('body').toggleClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').toggle();
                });

                $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
                    // e.preventDefault();
                    // $(this).next().slideToggle(300);
                    $(this).parent().toggleClass('active');
                });

                $(document).click(function (e) {
                    var container = $(".mobile-nav, .mobile-nav-toggle");
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        if ($('body').hasClass('mobile-nav-active')) {
                            $('body').removeClass('mobile-nav-active');
                            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                            $('.mobile-nav-overly').fadeOut();
                        }
                    }
                });
            } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
                $(".mobile-nav, .mobile-nav-toggle").hide();
            }
            // // Stick the header at top on scroll
            $("#header").sticky({
                topSpacing: 0,
                zIndex: '50'
            });
        }

        // Navigation active state on scroll
        const nav_sections = $('section');
        const main_nav = $('.nav-menu, .mobile-nav');

        $(window).on('scroll', function () {
            const cur_pos = $(this).scrollTop() + 200;

            nav_sections.each(function () {
                const top = $(this).offset().top,
                    bottom = top + $(this).outerHeight();

                if (cur_pos >= top && cur_pos <= bottom) {
                    if (cur_pos <= bottom) {
                        main_nav.find('li').removeClass('active');
                    }
                    main_nav.find('a[href="/#' + $(this).attr('id') + '"]').parent('li').addClass('active');
                }
                if (cur_pos < 300) {
                    $(".nav-menu ul:first li:first").addClass('active');
                }
            });

            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });

        // Back to top button
        $('.back-to-top').click(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1500, 'easeInOutExpo');
            return false;
        });

    })(jQuery);

    return (
        <>
            <Router history={historyService}>
                <Routes/>
            </Router>
            <div id="preloader"/>
            <Footer/>
        </>
    );
}

export default App;
