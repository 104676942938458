import React from 'react';
import './contact.css';

export function Contact() {
    return (
        <section id="contact" className="contact section-bg">
            <div className="container">

                <div className="section-title">
                    <h2>Contact</h2>
                    <p>You can send us an email, give us a call or come and visit us in person. We are always at your
                        service</p>
                </div>

                <div className="row info justify-content-center" data-aos="fade-right">
                    <div className="email col-lg-4 mt-3">
                        <i className="icofont-envelope"/>
                        <h4>Email:</h4>
                        <p>info@ellebenholdings.co.za</p>
                        <p>contact@ellebenholdings.co.za</p>
                    </div>

                    <div className="phone col-lg-4 mt-3">
                        <i className="icofont-phone"/>
                        <h4>Call:</h4>
                        <p>+27 11 554 5521</p>
                        <p>+27 11 123 5521</p>
                    </div>

                    <div className="address col-lg-4 mt-3">
                        <i className="icofont-google-map"/>
                        <h4>Location:</h4>
                        <p>1st Floor, Gateway West, 22 Magwa Cres, <br/>Waterval City, Johannesburg, <br/>2066
                        </p>
                    </div>


                </div>

            </div>
        </section>
    );
}
