import React from 'react';
import logo from '../assets/logo.png';
import {useLocation} from 'react-router-dom'
import './header.css';

export function Header() {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return (
        <header id="header" className="d-flex align-items-center">
            <div className="container">
                <nav className="nav-menu d-none d-lg-block">
                    <ul className="nav-inner">
                        <li className={isHomePage ? 'active' : ''}><a href="/">Home</a></li>
                        <li><a href="/#about">About</a></li>
                        <li className={'mr-auto'}><a href="/#why-us">Why Us</a></li>

                        <li className="nav-logo ml-5">
                            <a href="/#hero">
                                <img src={logo} alt="" className="img-fluid"/>
                            </a>
                        </li>

                        <li className={!isHomePage ? 'drop-down active' : 'drop-down ml-auto'}>
                            <a href={'/investment-portfolio'}>Investment Portfolio</a>
                            <ul>
                                <li><a href="https://africanpg.co.za" target="_blank" rel="noopener noreferrer">African
                                    Proficient Group</a></li>
                                <li><a href="http://ratheholdings.yelah.co.za" target="_blank"
                                       rel="noopener noreferrer">Rathe Holdings</a></li>
                                <li><a href="http://fmcg.yelah.co.za" target="_blank" rel="noopener noreferrer">The
                                    FMCG
                                    Group</a></li>
                                <li><a href="http://vestrotrim.yelah.co.za" target="_blank"
                                       rel="noopener noreferrer">Vestrotrim</a></li>
                                <li><a href="http://leveragepointinnovations.yelah.co.za/" target="_blank"
                                       rel="noopener noreferrer">Leverage Point
                                    Innovations</a></li>
                                <li><a href="http://masterpro.yelah.co.za/" target="_blank"
                                       rel="noopener noreferrer">MasterPro
                                    Service Contractors</a></li>
                                <li><a href="http://african-proficient-capital.yelah.co.za/" target="_blank"
                                       rel="noopener noreferrer">African Proficient
                                    Capital</a>
                                </li>
                                <li><a href="http://african-farming-operations.yelah.co.za/" target="_blank"
                                       rel="noopener noreferrer">African Farming
                                    Operations</a>
                                </li>
                                <li><a href="#" target="_blank" rel="noopener noreferrer">African Opulent
                                    Holdings</a>
                                </li>
                            </ul>
                        </li>
                        <li><a href="/#contact">Contact </a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
