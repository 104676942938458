import React from 'react';
import {About} from "./About/About";
import {WhyUs} from "./WhyUs/WhyUs";
import {Team} from "./Team/Team";
import {Contact} from "./Contact/Contact";
import {Hero} from "./Hero/Hero";
import {Header} from "../../Header/Header";

export function Home() {
    return (
        <>
            <Hero/>
            <Header/>
            <main id={'main'}>
                <About/>
                <WhyUs/>
                <Team/>
                <Contact/>
            </main>
            <a href="#hero" className="back-to-top"><i className="icofont-simple-up"/></a>
        </>
    )
}
