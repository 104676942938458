import React from 'react';
import './about.css';
import aboutImage from './about.jpg';

export function About() {
    return (
        <section id="about" className="about">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <h2>About Us</h2>
                    <p>Magnam dolores commodi suscipit eius consequatur</p>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-right">
                        <div className="image">
                            <img src={aboutImage} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-left">
                        <div className="content pt-4 pt-lg-0 pl-0 pl-lg-3 ">
                            <p className="font-italic">
                                Elleben Holdings is a holding company of group of companies, based in Johannesburg,
                                South Africa. The subsidiaries vary in nature of business, from Public and Private
                                Sector Consulting, to FMCG, to Opulent Artworks and Jewellery to Property.
                            </p>
                            <h3>Our Mission and Vision</h3>
                            <ul>
                                <li>
                                    <i className="bx bx-check-double"/> To be the number 1 African group of
                                    companies
                                </li>
                                <li>
                                    <i className="bx bx-check-double"/> To breathe and live excellence
                                </li>
                                <li>
                                    <i className="bx bx-check-double"/> to excel in all areas
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
