import React from 'react';
import './hero.css';

export function Hero() {
    return (
        <section id="hero">
            <div className="hero-container">
                <a href="/" className="hero-logo mt-1" data-aos="zoom-in">
                    {/*<img src={logo} alt=""/>*/}
                </a>
                <h1 data-aos="zoom-in">Welcome To Elleben Holdings</h1>
                <h2 data-aos="fade-up">We are a holding company of a group of companies, based in <strong>Johannesburg,
                    South
                    Africa</strong>. </h2>
                <a data-aos="fade-up" href="#about" className="btn-get-started scrollto">Get Started</a>
            </div>
        </section>
    );
}
