import React from 'react';
import {Switch} from 'react-router';
import Route from './RouteWrapper';
import {Home, InvestmentPortfolio} from "../Pages";


export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/investment-portfolio" component={InvestmentPortfolio}/>
            <Route component={Home}/>
        </Switch>
    );
}
